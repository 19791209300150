.thank-you-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6rem 16px 8rem 16px;
  gap: 12px;
}
.IoMdCheckmarkCircleOutline {
  font-size: 6rem;
  margin-bottom: 3rem;
}
.thank-you-greet {
  font-family: "Playfair";
  font-size: 34px;
  font-weight: 300;
  text-align: center;
}
.thank-you-greet-name {
  color: #790316;
  font-weight: 600;
}
.thank-you-greet-description {
  font-family: "Playfair";
  font-size: 20px;
  font-weight: 300;
  text-align: center;
}
.thank-you-greet-description-email {
  font-weight: 600;
  color: #790316;
  text-decoration: underline;
}
.thank-you-call-btn {
  background-color: #790316;
  color: #fff;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
  transition: 0.2s ease-in;
  text-decoration: none;
  padding: 8px 16px;
  text-align: center;
  border: 1.5px solid #f57b51;
  border-radius: 6px;
}
.welcoming-label {
  font-size: 28px;
  font-weight: 700;
  padding-top: 16px;
  font-family: Playfair;
  color: #790316;
  text-align: center;
}
@media screen and (max-width: 480px) {
  .IoMdCheckmarkCircleOutline {
    font-size: 4rem;
    margin-bottom: 1rem;
  }
  .thank-you-greet {
    font-size: 24px;
  }
  .thank-you-greet-description {
    font-size: 16px;
  }
  .thank-you-container {
    padding: 3rem;
  }
  .welcoming-label {
    font-size: 20px;
  }
}
