.container-sidebar {
  height: 100%;
  background-color: #fff;
  position: fixed;
  right: 0;
  box-shadow: #6363634d 0px 2px 12px 0px;
  transition: 0.5s;
  z-index: 999;
  top: 0;
}
.inactive {
  width: 0rem;
}
.active {
  width: 20rem;
}
.menuList {
  list-style: none;
  padding: 12px;
  border-bottom: #790316 solid 1px;
}
.menuList:first-child {
  border-top: 1px solid #790316;
}
.menuList:nth-child(3) {
  padding: 0px;
}
.menuListAnchor {
  text-decoration: none;
  font-size: 16px;
  color: #790316;
}

.menuListAnchor:nth-child(2) {
  padding: 12px;
}
.sidebar-rooms {
  padding: 10px;
  border-bottom: #790316 solid 1px;
  color: #790316;
  font-size: 14px;
  transition: 0.3s ease-in-out;
}

.sidebar-rooms:first-child {
  border-top: #790316 solid 1px;
}
.sidebar-rooms:last-child {
  border: 0px;
}
@media screen and (min-width: 960px) {
  .container-sidebar {
    display: none;
  }
}
@media screen and (max-width: 480px) {
  .active {
    width: 70%;
  }
}
