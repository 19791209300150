input[type="tel"],
input[type="email"],
input[type="password"],
input[type="text"],
input[type="number"],
textarea {
  width: 100%;
  outline: none;
  border: 1px solid #f8f3d4;
  padding: 12px 16px;
  font-style: italic;
  font-size: 16px;
  color: #7e7e7e;
}

input[type="number"].no-spinner::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
