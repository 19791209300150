.header-container {
  padding: 10px 40px;
  background-color: #f57a51;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 999;
}

.logo-wrapper {
  width: 5%;
  display: flex;
  align-items: center;
  gap: 10px;
}

.logo {
  width: 180px;
}

.logo1 {
  width: 80px;
}

.full-logo {
  width: 100px;
}
.LogoHorizontal-logo {
  width: 100px;
  display: none;
}
.header-menuList {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 1.5rem;
  margin-right: 1rem;
}
.menu-list-rooms,
.menu-list {
  list-style: none;
  font-size: 18px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  transition: 0.2s ease-in;
}
.menu-list-a {
  text-decoration: none;
  color: #fff;
}
.menu-list-contact {
  list-style: none;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  transition: 0.2s ease-in;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  border: #fff 1px solid;
  padding: 6px 14px;
  border-radius: 4px;
}
.menu-number {
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  color: #fff;
}
.menu-number-label {
  font-size: 12px;
  font-weight: 400;
}
.menu-list-rooms {
  position: relative;
}

.menu-list-a:hover {
  color: #790015;
  transition: 0.2s ease-in;
}
.room-dropDown-wrap {
  position: absolute;
  background-color: #f57a51;
  min-width: 10rem;
  top: 69px;
  right: -70px;
  display: flex;
  flex-direction: column;
  z-index: 9;
}

.room-dropDown-title {
  padding: 4px;
  border-top: 1px solid #fff;
  font-size: 14px;
}
.room-dropDown-title:hover {
  background-color: #790015;
  color: #fff;
}

.menu-list-btn {
  list-style: none;
}
.booking-mobile-btn,
.booking-btn {
  padding: 18px 30px;
  background-color: #790316;
  border: 1.5px solid #f57b51;
  color: #fff;
  outline: none;
  cursor: pointer;
  font-size: 18px;
  line-height: 25px;
  text-transform: uppercase;
  font-weight: 500;
  transition: 0.2s ease-in;
  border-radius: 6px;
  text-decoration: none;
}
.booking-btn:hover {
  background-color: #fff;
  color: #f57b51;
}
.IoCallOutline {
  font-size: 2rem;
}
.booking-mobile-btn {
  padding: 12px 30px;
}

@media screen and (max-width: 1150px) {
  .header-menuList {
    gap: 1rem;
  }
  .header-container {
    padding: 10px 0px;
    padding-left: 10px;
  }
}

@media screen and (max-width: 960px) {
  .header-menuList {
    display: none;
  }
  .header-container {
    padding: 10px;
  }

  .logo-wrapper {
    display: none;
  }

  .logo-mobile-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .burger-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
  .menu-list-contact {
    padding: 4px 10px;
  }
  .IoCallOutline {
    font-size: 1.5rem;
  }
  .menu-number {
    font-size: 14px;
    font-weight: 600;
  }
  .menu-number-label {
    font-size: 10px;
    font-weight: 400;
  }
  .menu-list-contact {
    gap: 0.8rem;
  }
}
@media screen and (min-width: 960px) {
  .logo-mobile-wrapper {
    display: none;
  }
}

@media screen and (max-width: 550px) {
  .booking-mobile-btn {
    display: none;
  }
  .menu-list-contact {
    display: none;
  }
  .full-logo {
    width: 80px;
    display: none;
  }

  .LogoHorizontal-logo {
    display: block;
    width: 150px;
  }
}
