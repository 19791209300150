* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.loading__screen__main__container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: rgb(116 116 116 / 64%);
  top: 0px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
}

.loading__screen__main__container-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loading__screen__main__container-box .loading__screen-box--text {
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 1.5rem;
  color: #efefef;

  margin-top: 0.7188rem;
}

.loading__screen-box--animation-div {
  height: 2.125rem;
  width: 2.125rem;
  border-radius: 50%;
  -webkit-mask: radial-gradient(
    farthest-side,
    transparent 0.8rem,
    #a16831 0
  );
  animation: spin 1s linear infinite;
  background: linear-gradient(#e44d11, #fee9d8);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
