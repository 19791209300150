.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 0;
  animation: fadeIn 0.3s ease forwards;
}

.modal-content {
  background-color: #ffb39a;
  padding: 20px;
  position: relative;
  width: 70%;
  max-height: 90vh;
  /* overflow-y: auto; */
  opacity: 0;
  transform: translateY(-20px);
  animation: slideIn 0.3s ease forwards;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 45px;
  cursor: pointer;
  padding: 5px 10px;
  z-index: 99;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Classes for exit animations */
.modal-overlay.closing {
  animation: fadeOut 0.3s ease forwards;
}

.modal-content.closing {
  animation: slideOut 0.3s ease forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slideOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(20px);
  }
}

@media (max-width: 768px) {
  .modal-content{
    width: 90%;
  }
}