@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Orbitron:wght@500;600;700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #d63447;
  --secondary: #f57b51;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #f57a516e;
}

.flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.block-title:after {
  position: absolute;
  content: "";
  background: #eee;
  width: 100px;
  height: 2px;
  left: 0;
  bottom: -2px;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -ms-transition: 0.3s ease;
  -o-transition: 0.3s ease;
}

.block:hover .block-title:after {
  width: 100%;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -ms-transition: 0.3s ease;
  -o-transition: 0.3s ease;
}

.pointer {
  cursor: pointer;
}
.width {
  width: 100% !important;
}

.textField {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

textarea {
  resize: none;
}

.container {
  max-width: 1200px;
  margin: 3rem auto 4rem;
  padding: 30px;
}

.title {
  color: #790316;
  font-size: 62px;
  position: relative;
  font-weight: normal;
  text-transform: capitalize;
  font-style: normal;
  text-decoration: none;
  line-height: 62px;
  letter-spacing: -1px;
  margin-bottom: 3rem;
  /* text-align: center; */
}

.title:after {
  position: absolute;
  content: "";
  background: #eee;
  width: 100px;
  height: 2px;
  left: 0;
  bottom: -15px;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -ms-transition: 0.3s ease;
  -o-transition: 0.3s ease;
}

.cursor {
  cursor: pointer;
}

.container:hover .title:after {
  width: 100%;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -ms-transition: 0.3s ease;
  -o-transition: 0.3s ease;
}
.form-field-wrap {
  width: 65%;
}
.benefits-wrap {
  width: 30%;
}
.benefits-heading {
  font-size: 16px;
  font-weight: 500;
  color: #780116;
  padding-bottom: 16px;
}
.benefits-list {
  font-size: 14px;
  font-weight: 400;
  color: #000;
  list-style: none;
  display: flex;
  align-items: baseline;
  gap: 8px;
  padding-bottom: 10px;
}
.overflow-control {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
}

.form-row {
  display: flex;
  gap: 12px;
  padding-top: 24px;
}

.info-form-title {
  color: #780116;
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 2px;
}
.form-row .form-group {
  flex: 1;
}

.form-group {
  margin-bottom: 15px;
}
.form-label {
  font-size: 12px;
}
.form-notice {
  display: block;
  color: #e74c3c;
  font-size: 12px;
  margin-top: 5px;
  font-style: italic;
}

.date-pickers {
  display: flex;
  gap: 20px;
}

.date-pickers .form-group {
  flex: 1;
}

.extend-stay-option {
  margin: 15px 0;
}

.checkbox-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: normal;
}

.checkbox-container input[type="checkbox"] {
  margin-right: 10px;
  cursor: pointer;
}

.checkbox-text {
  color: #34495e;
}

/* Custom styling for the date picker */
.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker {
  font-family: Arial, sans-serif;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.react-datepicker__header {
  background-color: #f8f9fa;
  border-bottom: 1px solid #ddd;
}

.react-datepicker__day--selected {
  background-color: #3498db;
}

.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background-color: rgba(52, 152, 219, 0.5);
}

.react-datepicker__day--keyboard-selected {
  background-color: #2980b9;
}

.error-message {
  font-size: 14px;
  color: rgb(246, 65, 108);
  margin: 2px;
}
.layout-container {
  margin-top: 7rem;
  flex: 1;
}
.mobile-view-contact {
  display: none;
}
.sticky-tool {
  position: fixed;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 99;
}
.whatsapp-btn {
  background-color: #25d366;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.whatsapp {
  color: #fff;
  width: 24px;
  height: 24px;
}
.call-btn {
  background-color: #3b82f6;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}
.arrow-btn {
  background-color: #fff;
  width: 10%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #790316;
}
.arrow-icon {
  transform: rotate(180deg);
}
.booking-btn-mobile-view {
  background-color: #790316;
  width: 100%;
  height: 40px;
  color: #fff;
  outline: none;
  cursor: pointer;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  transition: 0.2s ease-in;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.floating-wp {
  position: fixed;
  transform: all 0.5s ease;
  z-index: 1;
  right: 4rem;
  bottom: 4rem;
  color: #fff;
  cursor: pointer;
  background-color: #25d366;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.floating-wp:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background-color: #25d366;
  border-radius: 50%;
  -webkit-animation: pulse-border 1500ms ease-out infinite;
  animation: pulse-border 1500ms ease-out infinite;
}
.floating-wp:focus {
  border: none;
  outline: none;
}
.overflow-control {
  max-height: calc(100vh - 20vh);
  overflow-y: auto;
}
@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

/* Responsive adjustments */

@media (max-width: 768px) {
  .mobile-responsive {
    display: none;
  }
  .benefits-wrap {
    display: none;
  }
  .form-field-wrap {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .form-row {
    flex-direction: column;
    gap: 0;
  }

  .date-pickers {
    flex-direction: column;
    gap: 0;
  }
}
@media screen and (max-width: 550px) {
  .layout-container {
    margin-top: 4rem;
  }
  .floating-wp {
    display: none;
  }
}
@media screen and (max-width: 480px) {
  .title {
    margin-bottom: 20px;
    font-size: 35px;
  }
  .container {
    margin-top: 2rem;
  }
  .mobile-view-contact {
    display: block;
  }
}
@media screen and (max-width: 375px) {
  .title {
    font-size: 28px;
  }
}
