.footer {
  background: #f47a51;
  color: white;
  padding: 40px 30px;
  position: relative;
}

.footer-section {
  max-width: 1200px;
  margin: auto;
  display: flex;
  gap: 5rem;
}

.footer-block {
  width: 25%;
}

.footer-block img {
  width: 100px;
}

.footer-block p {
  font-size: 12px;
}

.footer-title {
  color: #780116;
  font-size: 20px;
  margin-bottom: 20px;
  position: relative;
  text-transform: uppercase;
}

.footer-title:after {
  background: black;
  position: absolute;
  content: "";
  width: 40px;
  height: 2px;
  left: 0;
  bottom: -5px;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -ms-transition: 0.3s ease;
  -o-transition: 0.3s ease;
}

.footer-block:hover .footer-title:after {
  width: 80px;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -ms-transition: 0.3s ease;
  -o-transition: 0.3s ease;
}

.footer-block li {
  list-style: none;
  line-height: 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}

.footer-block li a {
  text-decoration: unset;
  color: #fff;
  cursor: pointer;
}

.footer-block li a:hover {
  color: #780116;
}

.footer-block li .icon {
  width: 10%;
}

.footer-block li .contact-value {
  width: 90%;
}

.developer-block {
  text-align: center;
  background: #000000;
  color: white;
  padding: 15px;
}

.dev {
  cursor: pointer;
  text-decoration: underline;
  color: #f47a51;
}

.footer-social {
  display: flex;
  gap: 8px;
  margin-top: 10px;
  color: #790316;
  align-items: center;
}
.send-btn {
  padding: 10px 16px;
  background-color: #790316;
  color: #fff;
  outline: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 25px;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 6px;
  margin-top: 12px;
  outline: none;
  border: 0;
}
.room-cat {
  text-transform: capitalize;
}
.room-cat:hover {
  color: #780116;
  cursor: pointer;
}
@media screen and (max-width: 786px) {
  .footer-section {
    max-width: 786px;
    flex-wrap: wrap;
  }

  .footer-block {
    width: 100%;
  }

  .footer-section {
    gap: 2rem;
  }
}
@media screen and (min-width: 786px) {
  .footer-block {
    width: 30%;
  }
}
